import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import Typewriter from "typewriter-effect";

class HeroTypingText extends Component {
  render() {
    let content = this.props.content.edges;
    let results = [];
    results = content.map((item) => (item.node.service_hero_display_name_de));

    return (
      <>
        <Typewriter
          options={{
            strings: results,
            autoStart: true,
            loop: true,
          }}
        />
      </>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allStrapiServices(
          filter: { is_service_hero_display_name: { eq: true } }
        ) {
          edges {
            node {
              service_hero_display_name_de
            }
          }
        }
      }
    `}
    render={(data) => <HeroTypingText content={data.allStrapiServices} />}
  />
);
