import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";

class Services extends Component {
  render() {
    return (
      <>
        {this.props.columnOne.edges && (
          <div className="col-lg-4 col-12 px-0">
            {this.props.columnOne.edges.map((post, i) => (
              <div
                className={
                  i === 0
                    ? `col-12 service-item-${post.node.strapiId} p-0`
                    : `col-12 my-2 service-item-${post.node.strapiId} p-0`
                }
                key={i}
              >
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front d-flex align-items-center justify-content-center">
                      <p
                        className="font-weight-bold"
                        dangerouslySetInnerHTML={{
                          __html: post.node.service_name_de,
                        }}
                      />
                    </div>
                    <div className="flip-card-back d-flex align-items-center justify-content-center">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.node.service_description_de,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {this.props.columnTwo.edges && (
          <div className="col-lg-4 col-12 px-0 px-lg-2">
            {this.props.columnTwo.edges.map((post, i) => (
              <div
                className={`col-12 my-2 service-item-${post.node.strapiId} p-0`}
                key={i}
              >
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front d-flex align-items-center justify-content-center">
                      <p
                        className="font-weight-bold"
                        dangerouslySetInnerHTML={{
                          __html: post.node.service_name_de,
                        }}
                      />
                    </div>
                    <div className="flip-card-back d-flex align-items-center justify-content-center">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.node.service_description_de,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {this.props.columnThree.edges && (
          <div className="col-lg-4 col-12 px-0">
            {this.props.columnThree.edges.map((post, i) => (
              <div
                className={`col-12 my-2 service-item-${post.node.strapiId} p-0`}
                key={i}
              >
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front d-flex align-items-center justify-content-center">
                      <p
                        className="font-weight-bold"
                        dangerouslySetInnerHTML={{
                          __html: post.node.service_name_de,
                        }}
                      />
                    </div>
                    <div className="flip-card-back d-flex align-items-center justify-content-center">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.node.service_description_de,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        columnOne: allStrapiServices(filter: { column_id: { eq: 1 } }) {
          edges {
            node {
              strapiId
              service_name_de
              service_description_de
            }
          }
        }
        columnTwo: allStrapiServices(filter: { column_id: { eq: 2 } }) {
          edges {
            node {
              strapiId
              service_name_de
              service_description_de
            }
          }
        }
        columnThree: allStrapiServices(filter: { column_id: { eq: 3 } }) {
          edges {
            node {
              strapiId
              service_name_de
              service_description_de
            }
          }
        }
      }
    `}
    render={(data) => (
      <Services
        columnOne={data.columnOne}
        columnTwo={data.columnTwo}
        columnThree={data.columnThree}
      />
    )}
  />
);
