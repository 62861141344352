import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layouts/layout";
import Seo from "../components/headers/seo";
import ContactForm from "../components/forms/contactForm";
import HeroTypingText from "../components/partials/HeroTypingText";
import Clients from "../components/partials/Clients";
import Services from "../components/partials/Services";
import Locations from "../components/partials/Locations";

const IndexPage = (props) => (
  <Layout>
    <Seo
      lang="de-de"
      title={
        props.data.allStrapiLandingPageHomeSettings.edges[0].node
          .seo_meta_title_de
      }
      description={
        props.data.allStrapiLandingPageHomeSettings.edges[0].node
          .seo_meta_description_de
      }
      ogImage={
        props.data.allStrapiLandingPageHomeSettings.edges[0].node
          .seo_meta_og_image.publicURL
      }
      tweetImage={
        props.data.allStrapiLandingPageHomeSettings.edges[0].node
          .seo_meta_twitter_image.publicURL
      }
    />

    <div className="jumbotron jumbotron-fluid">
      <div className="container-fluid text-center">
        <h1
          className="hero-title"
          dangerouslySetInnerHTML={{
            __html:
              props.data.allStrapiLandingPageHomeSettings.edges[0].node
                .hero_heading_de,
          }}
        />
        <h2 className="hero-typing-text">
          <HeroTypingText />
        </h2>
        <img
          className="img-fluid mx-auto d-none d-sm-block"
          src={
            props.data.allStrapiLandingPageHomeSettings.edges[0].node
              .hero_image_desktop.publicURL
          }
          alt="Reach more people"
        />
      </div>
    </div>
    <Img
      className="img-fluid mx-auto d-block d-sm-none"
      fluid={
        props.data.allStrapiLandingPageHomeSettings.edges[0].node
          .hero_image_mobile.childImageSharp.fluid
      }
      alt="Reach more people"
    />
    <div className="about">
      <div className="container-custom">
        <p
          className="text-center"
          dangerouslySetInnerHTML={{
            __html:
              props.data.allStrapiLandingPageHomeSettings.edges[0].node
                .about_site_de,
          }}
        />
      </div>
    </div>

    <div
      id="our-presence"
      className="our-presence justify-content-center d-none d-md-block"
    >
      <div className="container-custom">
        <div className="row my-5">
          <div className="col-12 text-center p-0 map-locations">
            <Locations />
            <Img
              className="img-fluid"
              fluid={
                props.data.allStrapiLandingPageHomeSettings.edges[0].node
                  .world_map_image.childImageSharp.fluid
              }
              alt="Our Presence"
            />
          </div>
        </div>
      </div>
    </div>

    <div id="services" className="py-5 tree-3">
      <div className="tree-2">
        <div className="container-custom services">
          <h2
            className="title d-none d-sm-block"
            dangerouslySetInnerHTML={{
              __html:
                props.data.allStrapiLandingPageHomeSettings.edges[0].node
                  .services_heading_de,
            }}
          />
          <div className="services-inner">
            <div className="row mx-0">
              <div className="col-12 d-flex align-items-center justify-content-center service-item-1 bg-red mb-0 d-block d-sm-none">
                <p
                  className="font-weight-bold"
                  dangerouslySetInnerHTML={{
                    __html:
                      props.data.allStrapiLandingPageHomeSettings.edges[0].node
                        .services_heading_de,
                  }}
                />
              </div>
              <Services />
            </div>
          </div>
        </div>

        <div className="container-custom clients">
          <h2
            className="title"
            dangerouslySetInnerHTML={{
              __html:
                props.data.allStrapiLandingPageHomeSettings.edges[0].node
                  .clients_heading_de,
            }}
          />
          <Clients />
        </div>
      </div>
    </div>
    <div id="contact" className="contact">
      <div className="container-custom">
        <div className="row">
          <div className="col-lg-7 col-12">
            <h2
              className="title-white"
              dangerouslySetInnerHTML={{
                __html:
                  props.data.allStrapiLandingPageHomeSettings.edges[0].node
                    .contact_heading_de,
              }}
            />

            <ContactForm />

            <ul className="list-inline social-links text-center text-lg-left mb-5 mb-lg-0 d-none d-sm-block">
              <li className="list-inline-item mr-4">
                {
                  props.data.allStrapiLandingPageHomeSettings.edges[0].node
                    .social_links_text_de
                }
              </li>
              <li className="list-inline-item">
                <a
                  href="https://www.facebook.com/redhillHQ/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Follow us on Facebook"
                  aria-label="Follow us on Facebook"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://www.linkedin.com/company/redhill-asia/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Follow us on LinkedIn"
                  aria-label="Follow us on LinkedIn"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://www.instagram.com/redhill.world/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Follow us on Instagram"
                  aria-label="Follow us on Instagram"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://medium.com/redhill-review"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Follow us on Medium"
                  aria-label="Follow us on Medium"
                >
                  <i className="fab fa-medium-m"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://redhill.world"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Visit our Website"
                  aria-label="Visit our website"
                >
                  redhill.world
                </a>
              </li>
            </ul>
            <div className="row d-block d-sm-none">
              <div className="col-12 col-lg-4">
                <ul className="list-inline social-links m-social-links text-center text-lg-left">
                  <li className="list-inline-item">
                    {
                      props.data.allStrapiLandingPageHomeSettings.edges[0].node
                        .social_links_text_de
                    }
                  </li>
                </ul>
              </div>
              <div className="col-12 col-lg-8">
                <ul className="list-inline social-links text-center text-lg-left mb-5 mb-lg-0">
                  <li className="list-inline-item"></li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/redhillHQ/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Follow us on Facebook"
                      aria-label="Follow us on Facebook"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.linkedin.com/company/redhill-asia/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Follow us on LinkedIn"
                      aria-label="Follow us on LinkedIn"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/redhill.world/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Follow us on Instagram"
                      aria-label="Follow us on Instagram"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://medium.com/redhill-review"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Follow us on Medium"
                      aria-label="Follow us on Medium"
                    >
                      <i className="fab fa-medium-m"></i>
                    </a>
                  </li>
                  <li className="mt-3">
                    <a
                      href="https://redhill.world"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Visit our Website"
                      aria-label="Visit our website"
                    >
                      redhill.world
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-12 d-flex align-items-center">
            <img
              className="img-fluid mx-auto d-block"
              src={
                props.data.allStrapiLandingPageHomeSettings.edges[0].node
                  .contact_image.publicURL
              }
              alt="REDHILL World"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    allStrapiLandingPageHomeSettings {
      edges {
        node {
          about_site_de
          clients_heading_de
          contact_heading_de
          contact_image {
            publicURL
          }
          hero_heading_de
          hero_image_desktop {
            publicURL
          }
          hero_image_mobile {
            ...fluidImage
          }
          seo_meta_description_de
          seo_meta_og_image {
            publicURL
          }
          seo_meta_title_de
          seo_meta_twitter_image {
            publicURL
          }
          services_heading_de
          social_links_text_de
          world_map_image {
            ...fluidImage
          }
        }
      }
    }
  }
`;
