import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

class Clients extends Component {
  render() {
    return (
      <>
        {this.props.content.edges && (
          <div className="row justify-content-center">
            {this.props.content.edges.map((post, i) => (
              <div className="col-lg-3 col-6 my-lg-4" key={i}>
                <a
                  href={post.node.client_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={`Visit ${post.node.client_name} website`}
                  aria-label={`Visit ${post.node.client_name} website`}
                >
                  <Img
                    className="logos mx-auto d-block"
                    fluid={post.node.client_logo.childImageSharp.fluid}
                    alt={post.node.client_name}
                  />
                </a>
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      fragment fluidImage on File {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      query {
        allStrapiClients(
          filter: { site_id: { in: ["all", "de"] } }
          sort: { fields: strapiId, order: ASC }
        ) {
          edges {
            node {
              client_name
              client_logo {
                ...fluidImage
              }
              client_url
            }
          }
        }
      }
    `}
    render={(data) => <Clients content={data.allStrapiClients} />}
  />
);
