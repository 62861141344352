import React, { useState } from "react";
import axios from "axios";

export default () => {
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });
  const [inputs, setInputs] = useState({
    name: "",
    company: "",
    email: "",
    message: "",
  });
  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      setInputs({
        name: "",
        company: "",
        email: "",
        message: "",
      });
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };
  const handleOnChange = (e) => {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
    setStatus({
      submitted: false,
      submitting: false,
      info: { error: false, msg: null },
    });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
    axios({
      method: "POST",
      url: "https://formspree.io/f/moqpbdqo",
      data: inputs,
    })
      .then((response) => {
        handleServerResponse(
          true,
          "Vielen Dank! Wir melden uns bei Ihnen."
        );
      })
      .catch((error) => {
        handleServerResponse(false, error.response.data.error);
      });
  };
  return (
    <div>
      <form onSubmit={handleOnSubmit}>
        <div className="from-group">
        <input
          id="name"
          type="text"
          name="name"
          onChange={handleOnChange}
          required
          value={inputs.name}
          placeholder="Name"
          className="form-control"
        />
        </div>
        <div className="from-group">
        <input
          id="company"
          type="text"
          name="company"
          onChange={handleOnChange}
          required
          value={inputs.company}
          placeholder="Name der Firma"
          className="form-control"
        />
        </div>
        <div className="from-group">
        <input
          id="email"
          type="email"
          name="_replyto"
          onChange={handleOnChange}
          required
          value={inputs.email}
          placeholder="Email"
          className="form-control"
        />
        </div>
        <div className="from-group">
        <textarea
          id="message"
          name="message"
          onChange={handleOnChange}
          required
          value={inputs.message}
          placeholder="Nachricht"
          className="form-control"
        />
        </div>
        <ul className="list-inline text-center text-lg-left">
          <li className="list-inline-item"><button className="btn btn-primary" type="submit" disabled={status.submitting}>
          {!status.submitting
            ? !status.submitted
              ? "Absenden"
              : "Abgeschickt"
            : "Wird gesendet..."}
        </button></li>
        <li className="list-inline-item"><p className="ml-lg-3 mt-4 mt-lg-0 form-email text-center text-lg-left">oder Email an: <br className="d-block d-sm-none"/><strong>germany@redhill.world</strong></p></li></ul>
      </form>
      {status.info.error && (
        <div className="alert alert-warning mt-5"><strong>Fehler:</strong> {status.info.msg}</div>
      )}
      {!status.info.error && status.info.msg && <div className="alert alert-success mt-5">{status.info.msg}</div>}
   </div>
  );
};