import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";

class Locations extends Component {
  render() {
    return (
      <>
        {this.props.content.edges && (
            this.props.content.edges.map((post, i) => (
              <div className="location" style={{ top: `${post.node.location_top_position}%`, right:`${post.node.location_right_position}%`, left: `${post.node.location_left_position}%` }} key={i}>
              <div className="spot">
              {post.node.is_location_display_name ? <span className="country-name pop-right">{post.node.location_name_de}</span> : ``}
              </div>
            </div>
            ))
        )}
      </>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allStrapiLocations {
          edges {
            node {
              location_name_de
              location_top_position
              location_right_position
              location_left_position
              is_location_display_name
            }
          }
        }
      }
    `}
    render={(data) => <Locations content={data.allStrapiLocations} />}
  />
);
